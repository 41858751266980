import React from 'react';
import styles from '../Errors.module.scss';

export const CloudhealthError = () => {
    return (
        <div>
            <p className={styles.testing}>CUSTOM CLOUDHEALTH ERROR</p>
        </div>
    );
};
